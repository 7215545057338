import React from 'react';
import EasyStepsComponent from './easySteps';
import './easySteps.scss';

const EasySteps = () => {
  return (
    <>
      <EasyStepsComponent />
    </>
  );
};

export default EasySteps;
