import React, { useState } from 'react';
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import { useLocation } from 'react-router-dom';
import DashboardHeader from '../components/layout/header/dashboardHeader';
import DashboardFooter from '../components/layout/footer/dashboardFooter';
import { Cookies } from 'react-cookie';

const AppLayout = ({ children }) => {
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('lang') || 'en');
  const location = useLocation();
  const navArray = [`/${lang}/dashboard`, `/${lang}/account`, `/${lang}/logout`];
  const currentPath = location.pathname;

  const renderHeader = () => {
    if (navArray.some((path) => currentPath.startsWith(path))) {
      return <DashboardHeader />;
    } else {
      return <Header />;
    }
  };

  const renderFooter = () => {
    if (navArray.some((path) => currentPath.startsWith(path))) {
      return <DashboardFooter />;
    } else {
      return <Footer />;
    }
  };

  return (
    <React.Fragment>
      {renderHeader()}
      {children}
      {renderFooter()}
    </React.Fragment>
  );
};

export default AppLayout;
