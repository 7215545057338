import React, { useState } from 'react';
import './dashboardHeader.scss';
import { Link } from 'react-router-dom';
import { header_logo } from '../../../assets/images/header';
import { Dropdown, Space } from 'antd';
import { HiMenu } from 'react-icons/hi';
import { Cookies } from 'react-cookie';

const DashboardHeader = () => {
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('lang') || 'en');

  const items = [
    {
      label: (
        <Link to={`/${lang}/dashboard`}>
          <div className='mobile-menu-text'>{'Dashboard'}</div>
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to={`/${lang}/account`}>
          <div className='mobile-menu-text'>{'Account'}</div>
        </Link>
      ),
      key: '1'
    },
    {
      label: (
        <Link to={`/${lang}/`}>
          <div className='mobile-menu-text'>{'Log Out'}</div>
        </Link>
      ),
      key: '3'
    }
  ];
  return (
    <>
      <header className='site-header'>
        <div className='main_container'>
          <div className='Header-main'>
            <div className='header-logo'>
              <Link to={`/${lang}/`}>
                <img src={header_logo} alt='main logo' loading='lazy' />
              </Link>
            </div>

            <div className='dashboard-header-menu-icon'>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                placement='bottomRight'
                overlayclassNameName='mobile-menu-toggle-list dashboard-header-menu'
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <HiMenu />
                  </Space>
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
