import { Form, Input, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './loginModal.scss';
import { useState, React } from 'react';
import { Home } from '../../../pages';
import { Cookies } from 'react-cookie';

const LoginModal = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [isLoginOpen, setIsLoginOpen] = useState(true);
  const [lang] = useState(cookie.get('lang') || 'en');
  const handleClose = () => {
    setIsLoginOpen(false);
    navigate(`/${lang}`);
  };
  return (
    <div>
      <Home />
      <Modal
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        open={isLoginOpen}
        onCancel={handleClose}
        className='login-info-wrap'
      >
        <div className='login-modal-section'>
          <div className='login-modal-title'>
            <span>Login</span>
          </div>
          <div className='wrap-top-modal'>
            <div className='google-button-login'>
              <button className='login-bg-wrap login-with-google-btn'>Continue with Google</button>
            </div>
            <div className='google-button-login'>
              <button className='login-bg-wrap login-with-apple-btn'>Continue with Apple</button>
            </div>
            <div className='google-button-login'>
              <button className='login-bg-wrap login-with-facebook-btn'>Continue with Facebook</button>
            </div>
            <div className='auth-divider login-auth-divider'>
              <span className='line left'></span>
              <span className='or-text'>Or, login with email</span>
              <span className='line right'></span>
            </div>
            <Form>
              <div className='body-modal'>
                <div className='modal-login-form'>
                  <div className='mb'>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'ENTER_EMAIL_RESET_PASSWORD'
                        }
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input className='payment-inputs' name='email' placeholder='hello@mail.com' />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='password'
                    rules={[{ required: true, message: 'PASSWORD_IS_REQUIRED' }]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input type='password' name='password' placeholder='*****' />
                  </Form.Item>
                </div>
                <div className='submit-btn-modal'>
                  <button className='hl_cta_wrap'>Continue</button>
                </div>
                <div className='forget-pass-modal'>
                  <Link to={`/${lang}/reset-pass`}>
                    <p>Forgot Password?</p>
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;
