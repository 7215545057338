import { Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import './resetModal.scss';
import { useState, React } from 'react';
import { Home } from '../../../pages';
import { Cookies } from 'react-cookie';

const ResetModal = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [lang] = useState(cookie.get('lang') || 'en');
  const [isResetOpen, setIsResetOpen] = useState(true);
  const handleClose = () => {
    setIsResetOpen(false);
    navigate(`/${lang}`);
  };
  return (
    <div>
      <Home />
      <Modal
        centered
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        open={isResetOpen}
        onCancel={handleClose}
        className='reset-info-wrap'
      >
        <div className='reset-modal-section'>
          <div className='reset-modal-title'>
            <span> Reset Password </span>
          </div>
          <div className='wrap-top-modal'>
            <Form>
              <div className='body-modal'>
                <div className='modal-reset-form'>
                  <div className='mb'>
                    <Form.Item
                      label='Email Address'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'ENTER_EMAIL_RESET_PASSWORD'
                        }
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input className='payment-inputs' name='email' placeholder='hello@mail.com ' />
                    </Form.Item>
                  </div>
                </div>
                <div className='submit-btn-modal'>
                  <button className='hl_cta_wrap'>Set New Password</button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResetModal;
