import React, { useEffect } from 'react';
import './unsubscribe.scss';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
const UnsubscribeComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
      {/* <!---------------- unsubscribe section start -----------------> */}
      <div className='main-container'>
        <div className='unsubscribe-section'>
          <div className='text-content'>
            <div className='title'>
              <span>Unsubscribe</span>
            </div>
            <div className='description'>
              <p>Confirm your email address to unsubscribe from</p>
            </div>
            <form className='ant-form'>
              <div className='unsubscribe-form-item-control-input'>
                <div className='unsubscribe-item-control-input-content'>
                  <input
                    name='email'
                    type='email'
                    placeholder='info.filesedit@gmail.com '
                    id='email'
                    aria-required='true'
                    className='unsubscribe-input'
                    value=''
                  />
                  <div className='submit-btn'>
                    <button>
                      <MdOutlineMarkEmailRead />
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!---------------- unsubscribe section End -----------------> */}
    </>
  );
};

export default UnsubscribeComponent;
