import React from 'react';
import { add_file_icon, convert_file_icon, done_file_icon, file_formate_icon } from '../../../../assets/images/home';

const EasyStepsComponent = () => {
  return (
    <>
      {/* <!------------------- how Work Section Start -------------> */}
      <section className='how-work-section  blue-design-pattern pt-100'>
        <div className='main_container'>
          <div className='common-title'>
            <span>- How It Works</span>
            <h2>
              Get started with 3 <br />
              easy steps
            </h2>
          </div>
          <div className='how-work-box-wrap'>
            <div className='how-work-box'>
              {/* <div className="file-box-number">
                <h4>01</h4>
              </div> */}
              <div className='file-box-icon'>
                <img src={add_file_icon} alt='' />
              </div>
              <div className='file-box-content'>
                <h3>І Аdd Mу Fіlе</h3>
                <p>
                  Drag and drop is a user interface interaction that enables users to move files or data from one
                  location to another by using their mouse or touch input.
                </p>
              </div>
            </div>
            <div className='how-work-box'>
              {/* <div className="file-box-number">
                <h4>02</h4>
              </div> */}
              <div className='file-box-icon'>
                <img src={file_formate_icon} alt='' />
                <img src={convert_file_icon} alt='' />
                <img src={file_formate_icon} alt='' />
              </div>
              <div className='file-box-content'>
                <h3>І Сhооѕе Thе Fоrmаt</h3>
                <p>
                  When users click on or interact with a specific area of the interface, a list of choices &quot;drops
                  down&quot; from the menu. Users can then select one option from the list.
                </p>
              </div>
            </div>
            <div className='how-work-box'>
              {/* <div className="file-box-number">
                <h4>03</h4>
              </div> */}
              <div className='file-box-icon'>
                <img src={done_file_icon} alt='' />
              </div>
              <div className='file-box-content'>
                <h3>Аbrасаdаbrа</h3>
                <p>Аѕ іf bу mаgіс mу fіlе іѕ соnvеrtеd! І јuѕt hаvе tо dоwnlоаd іt.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------------------- how Work Section End -------------> */}
    </>
  );
};

export default EasyStepsComponent;
