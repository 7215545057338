import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Contact, Dashboard, Home, Pricing, Unsubscribe } from './pages';
import AppLayout from './layouts';
import './scss/style.scss';
import Account from './pages/account';
import LoginModal from './components/modals/loginModal/loginModal';
import ResetModal from './components/modals/resetModal/resetModal';
import ConvertFile from './pages/ConvertFile';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookie from './pages/Cookie';
import { useTranslation } from 'react-i18next';

const App = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = cookies.get('lang') || 'en';
    const pathParts = location.pathname.split('/').filter((part) => part);

    if (pathParts.length === 0 || !['en', 'fr'].includes(pathParts[0])) {
      navigate(`/${lang}`);
    } else {
      const currentLang = pathParts[0];
      if (currentLang !== lang) {
        i18n.changeLanguage(currentLang);
        cookies.set('lang', currentLang, { path: '/', sameSite: true });
      }
    }
  }, [location.pathname, navigate, i18n, cookies]);

  return (
    <React.StrictMode>
      <AppLayout>
        <Routes>
          <Route path='/:lang/' element={<Home />} />
          <Route path='/:lang/contact' element={<Contact />} />
          <Route path='/:lang/dashboard' element={<Dashboard />} />
          <Route path='/:lang/pricing' element={<Pricing />} />
          <Route path='/:lang/unsubscribe' element={<Unsubscribe />} />
          <Route path='/:lang/account' element={<Account />} />
          <Route path='/:lang/login' element={<LoginModal />} />
          <Route path='/:lang/reset-pass' element={<ResetModal />} />
          <Route path='/:lang/convert' element={<ConvertFile />} />
          <Route path='/:lang/privacy' element={<Privacy />} />
          <Route path='/:lang/terms' element={<Terms />} />
          <Route path='/:lang/cookie' element={<Cookie />} />
        </Routes>
      </AppLayout>
    </React.StrictMode>
  );
};

export default App;
