import React, { useEffect } from 'react';
import './account.scss';
const AccountComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <>
      <div className='main_root'>
        <div className='wpb_info_wrapper'>
          <div className='main_container'>
            <div className='mt-50 wpb_content_account account_bg_wrap'>
              <div className='account_title'>
                <h2>Account Information</h2>
              </div>
              <div className='account_info_inner'>
                <div className='wi-50 account_address'>
                  <strong>Email Address</strong>
                  <p>
                    <a>admin.joseph123@gmail.com </a>
                  </p>
                </div>
                <div className='wi-50 account_address'>
                  <strong>MEMBER SINCE</strong>
                  <p>
                    <a href='/en/my-account'>
                      <time className='' timestamp='2024-07-11T05:09:39.493Z'>
                        March 11, 2024
                      </time>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='wpb_content_billing account_bg_wrap'>
              <div className='account_title'>
                <h2>Billing Information</h2>
              </div>
              <div className='form_group_warp'>
                <form className='ant-form ant-form-horizontal css-mzwlov'>
                  <div className='account-container'>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='first-name'>
                        First Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='first-name'
                        type='text'
                        name='first_name'
                        inputmode='text'
                        placeholder='First Name'
                        className='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        value=''
                      />
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='last-name'>
                        Last Name <span className='required-field'>*</span>
                      </label>
                      <input
                        id='last-name'
                        type='text'
                        inputmode='text'
                        name='last_name'
                        placeholder='Last Name'
                        className='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        value=''
                      />
                    </div>
                    <div className='field-account address'>
                      <label className='lbl_wpb_wrapper' for='street-address'>
                        Street Address <span className='required-field'>*</span>
                      </label>
                      <input
                        id='street-address'
                        type='text'
                        name='address'
                        inputmode='text'
                        placeholder='Los Angeles, 9 Stonehedge Dr S'
                        className='ant-input css-mzwlov ant-input-outlined ant-input-prefix '
                        value=''
                      />
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='country'>
                        Country <span className='required-field'>*</span>{' '}
                      </label>
                      <select className='ant-input-prefix' name='country'>
                        <option value='' disabled=''>
                          Select country
                        </option>
                        <option value='AF'>Afghanistan</option>
                        <option value='AX'>Åland Islands</option>
                        <option value='AL'>Albania</option>
                        <option value='DZ'>Algeria</option>
                        <option value='AS'>American Samoa</option>
                        <option value='AD'>Andorra</option>
                        <option value='AO'>Angola</option>
                        <option value='AI'>Anguilla</option>
                        <option value='AQ'>Antarctica</option>
                        <option value='AG'>Antigua and Barbuda</option>
                        <option value='AR'>Argentina</option>
                        <option value='AM'>Armenia</option>
                        <option value='AW'>Aruba</option>
                        <option value='AU'>Australia</option>
                        <option value='AT'>Austria</option>
                        <option value='AZ'>Azerbaijan</option>
                        <option value='BS'>Bahamas</option>
                        <option value='BH'>Bahrain</option>
                        <option value='BD'>Bangladesh</option>
                        <option value='BB'>Barbados</option>
                        <option value='BY'>Belarus</option>
                        <option value='BE'>Belgium</option>
                        <option value='BZ'>Belize</option>
                        <option value='BJ'>Benin</option>
                        <option value='BM'>Bermuda</option>
                        <option value='BT'>Bhutan</option>
                        <option value='BO'>Bolivia, Plurinational State of</option>
                        <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                        <option value='BA'>Bosnia and Herzegovina</option>
                        <option value='BW'>Botswana</option>
                        <option value='BV'>Bouvet Island</option>
                        <option value='BR'>Brazil</option>
                        <option value='IO'>British Indian Ocean Territory</option>
                        <option value='BN'>Brunei Darussalam</option>
                        <option value='BG'>Bulgaria</option>
                        <option value='BF'>Burkina Faso</option>
                        <option value='BI'>Burundi</option>
                        <option value='CV'>Cabo Verde</option>
                        <option value='KH'>Cambodia</option>
                        <option value='CM'>Cameroon</option>
                        <option value='CA'>Canada</option>
                        <option value='KY'>Cayman Islands</option>
                        <option value='CF'>Central African Republic</option>
                        <option value='TD'>Chad</option>
                        <option value='CL'>Chile</option>
                        <option value='CN'>China</option>
                        <option value='CX'>Christmas Island</option>
                        <option value='CC'>Cocos (Keeling) Islands</option>
                        <option value='CO'>Colombia</option>
                        <option value='KM'>Comoros</option>
                        <option value='CG'>Congo</option>
                        <option value='CD'>Congo, Democratic Republic of the</option>
                        <option value='CK'>Cook Islands</option>
                        <option value='CR'>Costa Rica</option>
                        <option value='HR'>Croatia</option>
                        <option value='CU'>Cuba</option>
                        <option value='CW'>Curaçao</option>
                        <option value='CY'>Cyprus</option>
                        <option value='CZ'>Czechia</option>
                        <option value='CI'>Côte d'Ivoire</option>
                        <option value='DK'>Denmark</option>
                        <option value='DJ'>Djibouti</option>
                        <option value='DM'>Dominica</option>
                        <option value='DO'>Dominican Republic</option>
                        <option value='EC'>Ecuador</option>
                        <option value='EG'>Egypt</option>
                        <option value='SV'>El Salvador</option>
                        <option value='GQ'>Equatorial Guinea</option>
                        <option value='ER'>Eritrea</option>
                        <option value='EE'>Estonia</option>
                        <option value='SZ'>Eswatini</option>
                        <option value='ET'>Ethiopia</option>
                        <option value='FK'>Falkland Islands (Malvinas)</option>
                        <option value='FO'>Faroe Islands</option>
                        <option value='FJ'>Fiji</option>
                        <option value='FI'>Finland</option>
                        <option value='FR'>France</option>
                        <option value='GF'>French Guiana</option>
                        <option value='PF'>French Polynesia</option>
                        <option value='TF'>French Southern Territories</option>
                        <option value='GA'>Gabon</option>
                        <option value='GM'>Gambia</option>
                        <option value='GE'>Georgia</option>
                        <option value='DE'>Germany</option>
                        <option value='GH'>Ghana</option>
                        <option value='GI'>Gibraltar</option>
                        <option value='GR'>Greece</option>
                        <option value='GL'>Greenland</option>
                        <option value='GD'>Grenada</option>
                        <option value='GP'>Guadeloupe</option>
                        <option value='GU'>Guam</option>
                        <option value='GT'>Guatemala</option>
                        <option value='GG'>Guernsey</option>
                        <option value='GN'>Guinea</option>
                        <option value='GW'>Guinea-Bissau</option>
                        <option value='GY'>Guyana</option>
                        <option value='HT'>Haiti</option>
                        <option value='HM'>Heard Island and McDonald Islands</option>
                        <option value='VA'>Holy See</option>
                        <option value='HN'>Honduras</option>
                        <option value='HK'>Hong Kong</option>
                        <option value='HU'>Hungary</option>
                        <option value='IS'>Iceland</option>
                        <option value='IN'>India</option>
                        <option value='ID'>Indonesia</option>
                        <option value='IR'>Iran, Islamic Republic of</option>
                        <option value='IQ'>Iraq</option>
                        <option value='IE'>Ireland</option>
                        <option value='IM'>Isle of Man</option>
                        <option value='IL'>Israel</option>
                        <option value='IT'>Italy</option>
                        <option value='JM'>Jamaica</option>
                        <option value='JP'>Japan</option>
                        <option value='JE'>Jersey</option>
                        <option value='JO'>Jordan</option>
                        <option value='KZ'>Kazakhstan</option>
                        <option value='KE'>Kenya</option>
                        <option value='KI'>Kiribati</option>
                        <option value='KP'>Korea, Democratic People's Republic of</option>
                        <option value='KR'>Korea, Republic of</option>
                        <option value='KW'>Kuwait</option>
                        <option value='KG'>Kyrgyzstan</option>
                        <option value='LA'>Lao People's Democratic Republic</option>
                        <option value='LV'>Latvia</option>
                        <option value='LB'>Lebanon</option>
                        <option value='LS'>Lesotho</option>
                        <option value='LR'>Liberia</option>
                        <option value='LY'>Libya</option>
                        <option value='LI'>Liechtenstein</option>
                        <option value='LT'>Lithuania</option>
                        <option value='LU'>Luxembourg</option>
                        <option value='MO'>Macao</option>
                        <option value='MG'>Madagascar</option>
                        <option value='MW'>Malawi</option>
                        <option value='MY'>Malaysia</option>
                        <option value='MV'>Maldives</option>
                        <option value='ML'>Mali</option>
                        <option value='MT'>Malta</option>
                        <option value='MH'>Marshall Islands</option>
                        <option value='MQ'>Martinique</option>
                        <option value='MR'>Mauritania</option>
                        <option value='MU'>Mauritius</option>
                        <option value='YT'>Mayotte</option>
                        <option value='MX'>Mexico</option>
                        <option value='FM'>Micronesia, Federated States of</option>
                        <option value='MD'>Moldova, Republic of</option>
                        <option value='MC'>Monaco</option>
                        <option value='MN'>Mongolia</option>
                        <option value='ME'>Montenegro</option>
                        <option value='MS'>Montserrat</option>
                        <option value='MA'>Morocco</option>
                        <option value='MZ'>Mozambique</option>
                        <option value='MM'>Myanmar</option>
                        <option value='NA'>Namibia</option>
                        <option value='NR'>Nauru</option>
                        <option value='NP'>Nepal</option>
                        <option value='NL'>Netherlands</option>
                        <option value='NC'>New Caledonia</option>
                        <option value='NZ'>New Zealand</option>
                        <option value='NI'>Nicaragua</option>
                        <option value='NE'>Niger</option>
                        <option value='NG'>Nigeria</option>
                        <option value='NU'>Niue</option>
                        <option value='NF'>Norfolk Island</option>
                        <option value='MK'>North Macedonia</option>
                        <option value='MP'>Northern Mariana Islands</option>
                        <option value='NO'>Norway</option>
                        <option value='OM'>Oman</option>
                        <option value='PK'>Pakistan</option>
                        <option value='PW'>Palau</option>
                        <option value='PS'>Palestine, State of</option>
                        <option value='PA'>Panama</option>
                        <option value='PG'>Papua New Guinea</option>
                        <option value='PY'>Paraguay</option>
                        <option value='PE'>Peru</option>
                        <option value='PH'>Philippines</option>
                        <option value='PN'>Pitcairn</option>
                        <option value='PL'>Poland</option>
                        <option value='PT'>Portugal</option>
                        <option value='PR'>Puerto Rico</option>
                        <option value='QA'>Qatar</option>
                        <option value='RO'>Romania</option>
                        <option value='RU'>Russian Federation</option>
                        <option value='RW'>Rwanda</option>
                        <option value='RE'>Réunion</option>
                        <option value='BL'>Saint Barthélemy</option>
                        <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value='KN'>Saint Kitts and Nevis</option>
                        <option value='LC'>Saint Lucia</option>
                        <option value='MF'>Saint Martin (French part)</option>
                        <option value='PM'>Saint Pierre and Miquelon</option>
                        <option value='VC'>Saint Vincent and the Grenadines</option>
                        <option value='WS'>Samoa</option>
                        <option value='SM'>San Marino</option>
                        <option value='ST'>Sao Tome and Principe</option>
                        <option value='SA'>Saudi Arabia</option>
                        <option value='SN'>Senegal</option>
                        <option value='RS'>Serbia</option>
                        <option value='SC'>Seychelles</option>
                        <option value='SL'>Sierra Leone</option>
                        <option value='SG'>Singapore</option>
                        <option value='SX'>Sint Maarten (Dutch part)</option>
                        <option value='SK'>Slovakia</option>
                        <option value='SI'>Slovenia</option>
                        <option value='SB'>Solomon Islands</option>
                        <option value='SO'>Somalia</option>
                        <option value='ZA'>South Africa</option>
                        <option value='GS'>South Georgia and the South Sandwich Islands</option>
                        <option value='SS'>South Sudan</option>
                        <option value='ES'>Spain</option>
                        <option value='LK'>Sri Lanka</option>
                        <option value='SD'>Sudan</option>
                        <option value='SR'>Suriname</option>
                        <option value='SJ'>Svalbard and Jan Mayen</option>
                        <option value='SE'>Sweden</option>
                        <option value='CH'>Switzerland</option>
                        <option value='SY'>Syrian Arab Republic</option>
                        <option value='TW'>Taiwan, Province of China</option>
                        <option value='TJ'>Tajikistan</option>
                        <option value='TZ'>Tanzania, United Republic of</option>
                        <option value='TH'>Thailand</option>
                        <option value='TL'>Timor-Leste</option>
                        <option value='TG'>Togo</option>
                        <option value='TK'>Tokelau</option>
                        <option value='TO'>Tonga</option>
                        <option value='TT'>Trinidad and Tobago</option>
                        <option value='TN'>Tunisia</option>
                        <option value='TR'>Turkey</option>
                        <option value='TM'>Turkmenistan</option>
                        <option value='TC'>Turks and Caicos Islands</option>
                        <option value='TV'>Tuvalu</option>
                        <option value='UG'>Uganda</option>
                        <option value='UA'>Ukraine</option>
                        <option value='AE'>United Arab Emirates</option>
                        <option value='GB'>United Kingdom</option>
                        <option value='UM'>United States Minor Outlying Islands</option>
                        <option value='US'>United States</option>
                        <option value='UY'>Uruguay</option>
                        <option value='UZ'>Uzbekistan</option>
                        <option value='VU'>Vanuatu</option>
                        <option value='VE'>Venezuela, Bolivarian Republic of</option>
                        <option value='VN'>Viet Nam</option>
                        <option value='VG'>Virgin Islands, British</option>
                        <option value='VI'>Virgin Islands, U.S.</option>
                        <option value='WF'>Wallis and Futuna</option>
                        <option value='EH'>Western Sahara</option>
                        <option value='YE'>Yemen</option>
                        <option value='ZM'>Zambia</option>
                        <option value='ZW'>Zimbabwe</option>
                      </select>
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='city'>
                        City <span className='required-field'>*</span>{' '}
                      </label>
                      <input
                        id='city'
                        type='text'
                        name='city'
                        inputmode='text'
                        placeholder='Los Angeles'
                        className='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        value=''
                      />
                    </div>
                    <div className='field-account'>
                      <label className='lbl_wpb_wrapper' for='postal-code'>
                        Postal Code
                        <span className='required-field'>*</span>
                      </label>
                      <input
                        id='postal-code'
                        name='zipcode'
                        inputmode='text'
                        placeholder='90077'
                        className='ant-input css-mzwlov ant-input-outlined ant-input-prefix'
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                  <button className='payment__account' type='button'>
                    Save
                  </button>
                </form>
              </div>
            </div>
            <div className='wpb_content_subs account_bg_wrap'>
              <div className='account_title'>
                <h2>Subscriptions</h2>
              </div>
              <div className='subscriptions_info_wrap'>
                <span>Phone Tracking</span>
                <button>Cancel Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountComponent;
