import React from 'react';
import './convertAllType.scss';
import {
  convert_pdf_icon,
  convert_arrow_icon,
  convert_world_icon,
  convert_right,
  convert_png_icon,
  convert_jpg_icon
} from '../../../../assets/images/home';
import ConvertDropbox from '../../homeComponent/ConvertDropbox/ConvertDropbox';

const ConvertAllTypeComponent = () => {
  return (
    <>
      {/* <!------------------- how Work Section Start -------------> */}
      <div className='convert-info-wrap'>
        <section className='tool-work-section blue-design-pattern pt-100'>
          <div className='main_container'>
            <div className='common-title'>
              <span>- Tools</span>
              <h2>
                Соnvеrt аll tуреѕ <br />
                оf fіlеѕ
              </h2>
            </div>
            <div className='tool-work-box-wrap'>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert PDF to Editable World File</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_pdf_icon} alt='' />
                    <span>PDF</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_world_icon} alt='' />
                    <span>WORLD</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert World File to Editable PDF</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_world_icon} alt='' />
                    <span>WORLD</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_pdf_icon} alt='' />
                    <span>PDF</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert JPG to Editable PDF</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_jpg_icon} alt='' />
                    <span>JPG</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_pdf_icon} alt='' />
                    <span>PDF</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert PDF to High Quality JPG</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_pdf_icon} alt='' />
                    <span>PDF</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_jpg_icon} alt='' />
                    <span>JPG</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert JPG to PNG</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_jpg_icon} alt='' />
                    <span>JPG</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_png_icon} alt='' />
                    <span>PNG</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
              <div className='tool-work-box'>
                <div className='tool-box-head-content'>
                  <h4>Convert PNG to JPG</h4>
                </div>
                <div className='tool-box-icon-wrap'>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_png_icon} alt='' />
                    <span>PNG</span>
                  </div>
                  <div className='tool-box-icon-info'>
                    <img src={convert_arrow_icon} alt='' />
                  </div>
                  <div className='tool-box-icon-info tool-box-icon-text'>
                    <img src={convert_jpg_icon} alt='' />
                    <span>JPG</span>
                  </div>
                </div>
                <div className='tool-box-button'>
                  <button>
                    Convert Now
                    <img src={convert_right} alt='' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ConvertDropbox />
      </div>
      {/* <!------------------- how Work Section End -------------> */}
    </>
  );
};

export default ConvertAllTypeComponent;
