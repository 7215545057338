import React from 'react';
import { black_arrow_right, drop_circle_icon, file_encrypted_icon, file_icon } from '../../../../assets/images/home';
import '../FilesToolSection/filesToolSection.scss';

const ConvertDropbox = () => {
  return (
    <>
      <section className='about-file-converter-section'>
        <div className='main_container'>
          <div className='tool-about-box-wrap'>
            <div className='tool-about-box'>
              <div className='box-icon'>
                <img src={file_icon} alt='' />
              </div>
              <div className='about-box-content'>
                <h3>Convert All File Types</h3>
                <p>Convert anything from videos and websites to documents and pdfs online.</p>
                <a href='javascript:void(0)'>
                  Learn More <img src={black_arrow_right} alt='' />
                </a>
              </div>
            </div>
            <div className='tool-about-box'>
              <div className='box-icon'>
                <img src={drop_circle_icon} alt='' />
              </div>
              <div className='about-box-content'>
                <h3>Convert Files From Dropbox</h3>
                <p>Convert your files from Dropbox, Google Drive, OneDrive and Box.</p>
                <a href='javascript:void(0)'>
                  Learn More <img src={black_arrow_right} alt='' />
                </a>
              </div>
            </div>
            <div className='tool-about-box'>
              <div className='box-icon'>
                <img src={file_encrypted_icon} alt='' />
              </div>
              <div className='about-box-content'>
                <h3>Everything is Encrypted</h3>
                <p>All files are stored encrypted with AES 256. Only you can unlock them.</p>
                <a href='javascript:void(0)'>
                  Learn More <img src={black_arrow_right} alt='' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConvertDropbox;
