import React from 'react';
import ConvertFileComponent from './convertFile';

const ConvertFile = () => {
  return (
    <div>
      <ConvertFileComponent />
    </div>
  );
};

export default ConvertFile;
